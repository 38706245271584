import styled from 'styled-components';
import { Row, Select, Typography } from 'antd';
import { SelectProps } from 'antd/es/select';

export const RowSC = styled(Row)`
  padding: 0 16px 0 0;
`;

export const TitleSC = styled(Typography.Title)`
  padding: 0 0 16px 16px;
`;

export const SelectSC = styled(Select)<SelectProps>`
  width: 120px;
`;
